import { useMemo, useCallback } from 'react'
import {
    Podcast,
    PodcastEpisode,
    useGetPodcastsQuery,
    useGetPodcastLazyQuery,
    useUpdatePodcastStatusMutation,
    useGetRecentlyPlayedPodcastEpisodesQuery,
    LearnContentStatusProgressType,
    PodcastSeason,
    LearnContentStatusType,
    useUpdatePodcastLikesMutation,
    LearnContentStatusSubtype
} from '../../graphql/generated/autogenerated'
import {
    useTransformPodcast,
    useTransformPodcastEpisode,
    useTransformPodcastSeason
} from './transformers'
import { TransformedPodcast, TransformedPodcastEpisode } from './types'
import { ApolloError } from '@apollo/client'

export * from './transformers'
export * from './types'

export type UseFetchPodcastResult = {
    podcast: TransformedPodcast | null
    isLoading: boolean
    getPodcast: (id: string) => void
    notFound: boolean
    isPristine: boolean
    error: Error | ApolloError | null
}

export const useFetchPodcast = (): UseFetchPodcastResult => {
    const transformPodcast = useTransformPodcast()

    const [
        get,
        {
            loading,
            called,
            error = null,
            data = { learnV2: { getPodcast: null } }
        }
    ] = useGetPodcastLazyQuery({ errorPolicy: 'all' })

    const getPodcast = useCallback(
        (id: string) => {
            get({ variables: { podcastId: id } })
        },
        [get]
    )

    const podcast = useMemo(
        () =>
            data.learnV2?.getPodcast
                ? transformPodcast(data.learnV2?.getPodcast as Podcast)
                : null,
        [data.learnV2?.getPodcast]
    )

    return {
        podcast,
        isLoading: loading,
        getPodcast,
        notFound: called && !loading && podcast === null,
        isPristine: !called,
        error
    }
}

export const useFetchPodcasts = ({ pageSize }: { pageSize?: number } = {}) => {
    const transformPodcast = useTransformPodcast()

    pageSize = pageSize || 10

    const {
        called,
        refetch: refetchPodcasts,
        loading,
        data,
        fetchMore: fetchMorePodcasts
    } = useGetPodcastsQuery({
        variables: { page: 1, pageSize },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!data?.learnV2?.getPodcasts?.hasMore
    const currentPage = data?.learnV2?.getPodcasts?.page || 1
    const nextPage = currentPage + 1

    const podcasts = useMemo(
        () =>
            ((data?.learnV2?.getPodcasts?.items || []) as Podcast[])
                .filter((podcast) => !!podcast)
                .map(transformPodcast),
        [data?.learnV2?.getPodcasts?.items]
    )

    const noPodcasts = called && !loading && podcasts.length === 0

    const fetchMore = useCallback(() => {
        fetchMorePodcasts({ variables: { pageSize, page: nextPage } })
    }, [nextPage, pageSize, fetchMorePodcasts])

    const refetch = useCallback(() => {
        refetchPodcasts({ pageSize, page: 1 })
    }, [pageSize, refetchPodcasts])

    return {
        podcasts,
        fetchMore,
        refetch,
        isFetchingInitial: loading && podcasts.length === 0,
        isFetchingMore: loading && podcasts.length > 0,
        hasMore,
        noPodcasts
    }
}

export const useFetchRecentlyPlayedPodcastEpisodes = ({
    pageSize
}: { pageSize?: number } = {}) => {
    const transformPodcast = useTransformPodcast()
    const transformPodcastEpisode = useTransformPodcastEpisode()
    const transformPodcastSeason = useTransformPodcastSeason()

    pageSize = pageSize || 10

    const {
        called,
        refetch: refetchPodcastEpisodes,
        loading,
        data,
        fetchMore: fetchMorePodcastEpisodes
    } = useGetRecentlyPlayedPodcastEpisodesQuery({
        variables: { page: 1, pageSize },
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
    })

    const hasMore = !!data?.learnV2?.getRecentlyPlayedPodcastEpisodes?.hasMore
    const currentPage =
        data?.learnV2?.getRecentlyPlayedPodcastEpisodes?.page || 1
    const nextPage = currentPage + 1

    const episodes: TransformedPodcastEpisode[] = useMemo(() => {
        return (
            (data?.learnV2?.getRecentlyPlayedPodcastEpisodes?.items ||
                []) as PodcastEpisode[]
        )
            .filter((episode) => !!episode)
            .map((episode) => {
                const podcast = episode.podcast as Podcast
                const transformedPodcast = transformPodcast({
                    ...podcast,
                    seasons: []
                })
                const season = episode.season as PodcastSeason
                const transformedSeason = transformPodcastSeason(
                    {
                        ...season,
                        episodes: []
                    },
                    transformedPodcast
                )
                const transformedEpisode = transformPodcastEpisode(
                    episode,
                    transformedSeason
                )

                return transformedEpisode
            })
    }, [data?.learnV2?.getRecentlyPlayedPodcastEpisodes?.items])

    const noEpisodes = called && !loading && episodes.length === 0

    const fetchMore = useCallback(() => {
        fetchMorePodcastEpisodes({ variables: { pageSize, page: nextPage } })
    }, [nextPage, pageSize, fetchMorePodcastEpisodes])

    const refetch = useCallback(() => {
        refetchPodcastEpisodes({ pageSize, page: 1 })
    }, [pageSize, refetchPodcastEpisodes])

    return {
        refetch,
        hasMore,
        fetchMore,
        noEpisodes,
        episodes,
        isFetchingInitial: loading && episodes.length === 0,
        isFetchingMore: loading && episodes.length > 0
    }
}

export const useUpdatePodcastStatus = () => {
    const [updatePodcastStatus, { data, loading }] =
        useUpdatePodcastStatusMutation()

    const update = useCallback(
        (
            args: {
                contentId: string
                progressInSeconds: number
                totalInSeconds: number
                isComplete: boolean
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, progressInSeconds, totalInSeconds, isComplete } =
                args

            const progressType =
                LearnContentStatusProgressType[
                    isComplete ? 'Finished' : 'InProgress'
                ]

            updatePodcastStatus({
                variables: {
                    input: {
                        contentId,
                        progressType,
                        progressInSeconds: Math.round(progressInSeconds),
                        totalInSeconds: Math.round(totalInSeconds)
                    }
                },
                refetchQueries
            })
        },
        [updatePodcastStatus]
    )

    const podcastStatus = useMemo(() => {
        if (data?.learnV2?.updatePodcastStatus)
            return {
                ...data.learnV2.updatePodcastStatus
            }

        return null
    }, [data])

    return {
        update,
        podcastStatus,
        isUpdating: loading
    }
}

export const useHandlePodcastLikeClicked = () => {
    const [updatePodcastLikes, { data: podcastData, loading: isLoading }] =
        useUpdatePodcastLikesMutation()

    const markAsLiked = useCallback(
        (
            args: {
                contentId: string
                likes: number
            },
            refetchQueries: string[] = []
        ) => {
            const { contentId, likes } = args

            const contentSubtype = LearnContentStatusSubtype.Audio
            const contentType = LearnContentStatusType.PodcastEpisode

            const podcastData = {
                contentId,
                contentSubtype,
                contentType,
                likes
            }

            updatePodcastLikes({
                variables: {
                    input: podcastData
                },
                refetchQueries
            })
        },
        [updatePodcastLikes]
    )

    const contentLikesStatus = useMemo(() => {
        if (podcastData?.learnV2?.updateContentLikes)
            return {
                ...podcastData.learnV2.updateContentLikes
            }

        return null
    }, [podcastData])

    return {
        markAsLiked,
        contentLikesStatus,
        isUpdating: isLoading
    }
}
