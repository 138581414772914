import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { captureException, store } from '@thriveglobal/thrive-web-core'
import { ThemeProvider } from '@mui/material'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import {
    ErrorScreen,
    applicationTheme,
    ErrorScreenVariant
} from '@thriveglobal/thrive-web-leafkit'

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        captureException(err, { info })

        const locale = store?.getState?.()?.settings?.locale ?? 'en-US'

        // Customize the root error boundary for your microfrontend here.
        return (
            <ThemeProvider theme={applicationTheme}>
                <IntlProvider locale={locale} defaultLocale="en-US">
                    <BrowserRouter>
                        <ErrorScreen variant={ErrorScreenVariant.General} />
                    </BrowserRouter>
                </IntlProvider>
            </ThemeProvider>
        )
    }
})

export * from './shared'

export const { bootstrap, mount, unmount } = lifecycles
